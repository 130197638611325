import React from "react"
import Layout from "../components/layout"
import Markdown from "react-markdown"

export const query = graphql`
  query privacyQuery {
    strapiPrivacy {
      body
      seo {
        metaTitle
        metaDescription
        shareImage {
          publicURL
        }
      }
    }
  }
`
export default function Privacy({ data, location }) {
  const seo = { ...data.strapiPrivacy.seo, url: location.pathname }

  return (
    <Layout seo={seo}>
      <div className="container mx-auto px-4 mb-24 mt-12">
        <Markdown
          className="markdown green-bullets"
          source={data.strapiPrivacy.body}
          escapeHtml={false}
        />
      </div>
    </Layout>
  )
}
